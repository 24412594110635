import './App.css';
import TableForm from './components/TableForm';
import Header from './components/Header';
import Route from './components/Route';
import React, { PureComponent } from "react";
import './styles/commonStyles.css';
import AdminPage from './components/AdminPage';
import LoginPage from './components/LoginPage';


class App extends PureComponent {

  render() {
    return (
      <div className="App">
        <Route exact path='/'>
          <div>
            <Header />
            <TableForm />
          </div>
        </Route>
        <Route exact path='/admin'>
          <AdminPage />
        </Route>
        <Route exact path='/login'>
          <LoginPage />
        </Route>
      </div>
    );
  }
}

export default App;
