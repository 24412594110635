import React, { PureComponent } from "react";
import '../styles/tableForm.css';
import '../styles/commonStyles.css'
import { debounce } from '../helper';
import api from '../api';

const options = [{ name: 'Ange grödor i växtföljden' }, { name: 'Ange skörd ton/ha' }, { name: 'Halm' }, { name: 'Bortförsel', secondName: 'kg P/ ha' }, { name: window.innerWidth > 768 ? 'Rekommendation' : 'Rek', secondName: 'kg P / ha', className: 'border-line' }, { name: 'Bortförsel', secondName: 'kg K / ha' }, { name: window.innerWidth > 768 ? 'Rekommendation' : 'Rek', secondName: 'kg K / ha' }]

class TableForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            PAL: 10,
            KAL: 10,
            cropOptions: [],
            weedingCropOptions: [],
            lists: [{ Crop: 'Höstvete', Skord: 0 }]
        }
    }

    componentDidMount() {
        this.load();
        this.loadWeedingOption();
    }
    load = async () => {
        const cropOptions = await api({
            url: '/api/Calculate'
        }) || [];
        this.setState({ cropOptions })
        this.setFirstList();
    }
    loadWeedingOption = async () => {
        const weedingCropOptions = await api({
            url: '/api/WeedingCrop'
        }) || [];
        this.setState({ weedingCropOptions })
        this.setFirstList();
    }
    setFirstList() {
        if (this.state.cropOptions && this.state.weedingCropOptions) {
            this.setState({ lists: [{ Crop: this.state.cropOptions[0], ShowWeeding: this.state.weedingCropOptions.findIndex(m => m === this.state.cropOptions[0]) > -1, Skord: 0 }] })
        }
    }

    onAdd = () => {
        const lists = [...this.state.lists];
        lists.push({
            Crop: this.state.cropOptions[0],
            ShowWeeding: this.state.weedingCropOptions.findIndex(m => m === this.state.cropOptions[0]) > -1,
            Skord: 0
        });

        this.setState({ lists })
    }

    onDelete = (index) => {
        const lists = [...this.state.lists];
        lists.splice(index, 1)
        this.setState({ lists }, this.onCalculation)
    }

    handleChange = (e, index, type) => {
        const lists = [...this.state.lists];
        lists[index][type] = Number(e.target.value) >= 0 ? e.target.value : 0;
        this.setState({ lists }, this.onCalculation)
    }

    handleCheck = (e, index, type) => {
        const lists = [...this.state.lists];
        lists[index][type] = e.target.checked;
        this.setState({ lists }, this.onCalculation)
    }

    onChange = (index, e) => {
        const lists = [...this.state.lists];
        lists[index].Crop = e.target.value;
        lists[index].ShowWeeding = this.state.weedingCropOptions.findIndex(m => m === e.target.value) > -1;
        this.setState({ lists }, this.onCalculation)
    }

    onCalculation = debounce(async () => {
        const { PAL, KAL, lists } = this.state;
        this.setState({ loading: true, })
        try {
            const results = await api({
                url: '/api/Calculate',
                method: 'post',
                body: { PAL, KAL, CropItems: lists }
            })
            this.setState({ loading: false, results })
        } catch (e) {
            this.setState({ error: e.message || 'Något fel inträffade', loading: false })
            setTimeout(() => {
                this.setState({ error: '' })
            }, 3000);
        }
    }, 1000)

    render() {

        const { results = [], loading, error, cropOptions = [] } = this.state;
        const bortPTotal = Math.round(results.reduce((a, b) => a + parseInt(b.BortP.toFixed(0)), 0))
        const recoPTotal = Math.round(results.reduce((a, b) => a + parseInt(b.RecoP.toFixed(0)), 0))
        const bortKTotal = Math.round(results.reduce((a, b) => a + parseInt(b.BortK.toFixed(0)), 0))
        const recoKTotal = Math.round(results.reduce((a, b) => a + parseInt(b.RecoK.toFixed(0)), 0))
        const kls = [{ key: 'PAL', label: 'P-AL-tal', helper: 'P-AL värdet du skriver in är ett medeltal av det fältet/område som du vill göra beräkningen för. Medeltalet får du genom att uppskatta eller räkna ett medelvärde av P-AL från din markkarta.    ' }, { key: 'KAL', label: 'K-AL-tal', helper: 'K-AL värdet du skriver in är ett medeltal av det fältet/område som du vill göra beräkningen för. Medeltalet får du genom att uppskatta eller räkna ett medelvärde av K-AL från din markkarta. ' }]
        return (
            <div className='form-contain'>
                <div className='form-header white-back-color primary-border alignCenter'>
                    <div className='form-header-title flexHor flexBetween alignCenter'>
                        {
                            kls.map(k => (
                                <div key={k.key} className='top-con alignCenter'>
                                    <div className='question-mark'>
                                        <span className='tooltiptext'>{k.helper}</span>
                                        <p>Ange</p>
                                        <p className='head-left' style={{ whiteSpace: 'nowrap' }}>{k.label}</p>
                                    </div>
                                    <div className='head-right horArrange flexEnd'>
                                        <input onFocus={(e) => e.target.select()} className='head-input primary-border'
                                            type='number'
                                            value={this.state[k.key]}
                                            onChange={e => {
                                                const o = { ...this.state }
                                                o[k.key] = Number(e.target.value) >= 0 ? e.target.value : 0;
                                                this.setState(o, this.onCalculation)
                                            }} />
                                        <p >mg/100 g jord</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='loading'>{loading ? 'Läser in...' : ''}</div>
                {error && <div className='loading' style={{ color: 'red' }}>{error}</div>}
                <div className='form-body white-back-color primary-border flexBetween'>
                    <table>
                        <thead>
                            <tr>
                                {options.map((item, index) => {
                                    return (
                                        <th className={item.className} style={index === 0 ? { fontSize: 20 } : { fontSize: 18 }} key={item.name + '_' + index}>
                                            <div>{item.name}</div>
                                            <div>{item.secondName}</div>
                                        </th>
                                    )
                                })}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.lists.map((item, index) => {
                                return (
                                    <tr key={index + '_' + item.Crop}>
                                        <td>
                                            <div>
                                                <select value={item.Crop} style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/dropDown.svg'})` }} className='select-style white-back-color' onChange={(e) => this.onChange(index, e)}>
                                                    {cropOptions.map((data) => {
                                                        return (
                                                            <option value={data} key={data}>{data}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </td>
                                        <td >
                                            <div className='table-input-con' style={{ display: 'inline-block' }}>
                                                <input className='table-input'
                                                    title='Decimaltecken skrivs med punkt'
                                                    onFocus={(e) => {
                                                        e.target.select()
                                                    }}
                                                    type='number'
                                                    onChange={(e) => this.handleChange(e, index, 'Skord')} value={typeof item.Skord === 'undefined' ? 0 : item.Skord} />
                                                <span className='table-input-hint'>Decimaltecken skrivs med punkt</span>
                                            </div>
                                        </td>
                                        <td >
                                            <div style={{ display: 'flex' }} className={item.ShowWeeding ? '' : 'hide-weeding'} >
                                                <input className='table-input' title='Weeding' onFocus={(e) => e.target.select()} type='checkbox' onChange={(e) => this.handleCheck(e, index, 'AddWeeding')} value={typeof item.AddWeeding === 'undefined' ? 0 : item.AddWeeding} />
                                            </div>
                                        </td>
                                        <td><div>{Math.round((results[index] && results[index].BortP) || 0)}</div></td>
                                        <td><div>{Math.round((results[index] && results[index].RecoP) || 0)}</div></td>
                                        <td><div>{Math.round((results[index] && results[index].BortK) || 0)}</div></td>
                                        <td><div>{Math.round((results[index] && results[index].RecoK) || 0)}</div></td>
                                        <td style={{ paddingRight: 10 }}>
                                            <div className='btn-con' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <button className='reduce-button' type='button' disabled={this.state.lists.length === 1} onClick={() => this.onDelete(index)}>
                                                    <img src={process.env.PUBLIC_URL + '/images/reduce.svg'} className='action-btn' alt="loading" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            <tr>
                                {[{ value: <img src={process.env.PUBLIC_URL + '/images/add.svg'} className='action-btn' alt="loading" onClick={this.onAdd} /> }, { value: 'Summa' }, { value: '' }, { value: bortPTotal }, { value: recoPTotal }, { value: bortKTotal }, { value: recoKTotal }, { value: '' }].map((sun, index) => {
                                    return (
                                        <td key={sun.value + '_' + index}><div>{sun.value}</div></td>
                                    )
                                })}
                            </tr>
                            <tr >
                                <td className='white-back-color'>Balans över växtföljd</td>
                                <td className='white-back-color'></td>
                                <td className='white-back-color'></td>
                                <td className='white-back-color'></td>
                                <td className='white-back-color'><div>{Math.round(recoPTotal - bortPTotal)} kg P/ha</div></td>
                                <td className='white-back-color'></td>
                                <td className='white-back-color'><div>{Math.round(recoKTotal - bortKTotal)} kg K/ha</div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default TableForm;