import React, { Component } from 'react';
import ConfigureItem from './ConfigureItem';
import '../styles/adminPage.css';
import api from '../api';

const columnMap = {
    'Crop': 'Grödor',
    'Juster': 'Justering',
    'Bort': 'Bortförsel',
    'Break1': 'brytpunkt1',
    'Break2': 'brytpunkt2',
    'Break3': 'brytpunkt3',
    'AndelHalmav': 'Andel halm av kärna',
    'Fosfor': 'Fosfor i halm',
    'Kalium': 'Kalium i halm',
    'BortPRate': 'Bortförsel P',
    'BortKRate': 'Bortförsel K',
}

class KalConfigure extends Component {

    state = {
        items: []
    }

    async componentDidMount() {
        const items = await api({
            url: `/api/${this.props.type}`,
        }) || []
        this.setState({ items })
    }


    add = () => {
        const { items } = this.state;
        if (this.props.type === 'Weeding') {
            items.push({
                'Crop': '',
                'Andel': '',
                'Fosfor': '',
                'Kalium': '',
                'BortPRate': 0.85,
                'BortKRate': 0.85
            });
        } else {
            items.push({});
        }
        this.setState({ items });
    }
    remove = (i) => {
        const items = [...this.state.items]
        items.splice(i, 1)
        this.setState({ items })
    }

    onChange = (item, i) => {
        const { items } = this.state;
        items[i] = item
        this.setState({ items })
    }

    submit = async (e) => {
        e.preventDefault()
        const isValid = this.formRef.checkValidity()
        if (isValid) {
            this.setState({ loading: true })
            try {
                await api({
                    url: `/api/${this.props.type}`,
                    method: 'post',
                    body: {
                        data: JSON.stringify(this.state.items)
                    }
                })
                alert('Save successfully');
            } catch (error) {
                alert('Some error happened')
            } finally {
                this.setState({ loading: false })
            }

        }
    }

    render() {
        const { items = [], loading } = this.state;
        const { columns = [], } = this.props;
        return (
            <form ref={e => this.formRef = e} onSubmit={this.submit}>
                <div className='flexLayout line-item'>
                    {
                        columns.map(c => (<div key={c} className={`count title ${c === 'Crop' ? 'crop' : ''}`}>{columnMap[c] || c}</div>))
                    }
                    <span style={{ width: 50 }}></span>
                </div>
                {
                    items.map((i, k) => <ConfigureItem keys={columns} key={`item_${k}`} item={i} index={k} onChange={this.onChange} onDelete={this.remove} />)
                }
                <div className='flexBetween' style={{ marginTop: 20 }}>
                    <div className='add-more-btn' onClick={this.add}>Lägg till gröda</div>
                    <button type="submit" style={{ width: 200, fontSize: '2rem' }} className="btn btn-primary">{loading ? 'Loading' : 'Save'}</button>
                </div>
            </form>
        );
    }
}

export default KalConfigure;