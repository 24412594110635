import React from 'react'

const slugRegex = /:[a-z]*/ig // :id, :name, etc.

class Route extends React.Component {
  match () {
    let { path, isNot } = this.props
    const slugs = path.match(slugRegex) // find slugs
    if (slugs) {
      path = path.replace(slugRegex, '([^/]*)') // replace slugs and create strict regex
    }
    if (this.props.exact) {
      path = '^' + path + '$' // do an exact match?
    }
    if (this.props.startsWith) {
      path = '^' + path // should start with?
    }
    const vals = window.location.pathname.match(path) // match the route
    if ((isNot && !vals) || (!isNot && vals)) {
      const props = {}
      slugs && slugs.forEach((slug, index) => { // replace any variable names
        props[slug.substr(1)] = vals[index + 1]
      })
      return props
    }
  }

  render () {
    const props = this.match()
    if (props) {
      return React.cloneElement(this.props.children, props)
    } else {
      return null
    }
  }
}

export default Route
