import React, { Component } from 'react';

class ConfigureItem extends Component {
    render() {
        const { item, index, onChange, keys } = this.props
        return (
            <div className='flexLayout line-item'>
                {
                    keys.map((k, i) => (
                        <div key={`input_${k}_${i}`} className={`count ${k === 'Crop' ? 'crop' : ''}`}>
                            <input required value={item[k]} name={k} type={k === 'Crop' ? undefined : 'number'} step={k === 'Crop' ? undefined : 0.1} onChange={(e) => {
                                const val = { ...item }
                                val[k] = e.target.value
                                onChange(val, index)
                            }} />
                        </div>
                    ))
                }
                <button className='reduce-button' style={{ width: 50 }} type='button' onClick={() => this.props.onDelete(index)}>
                    <img src={process.env.PUBLIC_URL + '/images/reduce.svg'} style={{ height: '100%' }} alt="loading" />
                </button>
            </div>
        );
    }
}

export default ConfigureItem;