import React, { Component } from 'react';
import api from '../api';

const style = {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/background.png'})`,
    height: 200,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '0 10vw'
}

const marginTopStyle = {
    marginTop: 20,
    width: '100%',
    marginRight: 0,
    marginLeft: 0,
    height: 56,
    fontSize: 24,
}

class LoginPage extends Component {
    state = {
        Email: "",
        Password: ""
    }

    onSubmit = async (e) => {
        e.preventDefault();
        const isValid = this.formRef.checkValidity()
        if (isValid) {
            try {
                const { Token } = await api({
                    url: "/api/Authorize",
                    method: 'post',
                    body: this.state
                })
                window.localStorage.setItem('token', Token)
                window.location.href = '/admin'
            } catch (e) {
                alert(e.Message)
            }
        }

    }
    render() {
        return (
            <div>
                <div style={style} className='flexBetween'>
                    <img src={process.env.PUBLIC_URL + '/images/logo.svg'} alt="loading" className='logo-pic' />
                </div>
                <div className='justifyCenter'>
                    <form style={{ marginTop: 100, width: '80vw', maxWidth: 500, flexDirection: 'column' }} className="alignCenter" ref={e => this.formRef = e} onSubmit={this.onSubmit}>
                        <input style={marginTopStyle} value={this.state.Email} className="head-input primary-border" onChange={v => this.setState({ Email: v.target.value })} required name="Email" placeholder="Email" />
                        <input style={marginTopStyle} value={this.state.Password} className="head-input primary-border" onChange={v => this.setState({ Password: v.target.value })} required type='password' placeholder="Password" name="Password" />
                        <button style={marginTopStyle} type="submit" className="btn btn-primary">logga in</button>
                    </form>
                </div>

            </div>
        );
    }
}

export default LoginPage;