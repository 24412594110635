import React, { Component } from 'react';
import '../styles/commonStyles.css'
import '../styles/adminPage.css';
import Configure from './Configure';
import api from '../api';

const style = {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/background.png'})`,
    height: 200,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '0 10vw'
}
class AdminPage extends Component {
    state = { active: 'forsfor', logged: false }
    componentDidMount() {
        this.authorize()
    }
    authorize = async () => {
        try {
            var token = window.localStorage.getItem('token');
            const user = await api({
                url: `/api/Authorize?token=${token}`
            })
            this.setState({ logged: true, user })
        } catch (error) {
            console.log(error)
            window.localStorage.removeItem('token')
            window.location.href = '/login'
        }
    }

    render() {
        const { active, user = {}, logged } = this.state;
        return logged ? (
            <div>
                <div style={style} className='flexBetween horArrange'>
                    <img src={process.env.PUBLIC_URL + '/images/logo.svg'} alt="loading" className='logo-pic' />
                    <h1>{user.Email}</h1>
                </div>
                <div className='flexHor' style={{ padding: '0 10vw' }}>
                    <div className='col-md-2'>
                        <div className='menu-item background-primary-color' onClick={() => this.setState({ active: 'forsfor' })}>Fosfor</div>
                        <div className='menu-item background-primary-color' onClick={() => this.setState({ active: 'kalium' })}>Kalium</div>
                        <div className='menu-item background-primary-color' onClick={() => this.setState({ active: 'Weeding' })}>Halm</div>
                        <div className='menu-item background-primary-color' onClick={() => { window.localStorage.removeItem('token'); window.location.href = '/login' }}>Logout</div>
                    </div>
                    <div style={{ minWidth: 1400 }} className='col-md-10'>
                        {active === 'forsfor' && <Configure type='Pal' columns={['Crop', 'Juster', 'Bort', 'Konstant1', 'Konstant2', 'Konstant4', 'Max']} />}
                        {active === 'kalium' && <Configure type='Kal' columns={['Crop', 'Juster1', 'Juster2', 'Juster3', 'Bort', 'Konstant1', 'Konstant2', 'Konstant3', 'Konstant4', 'Konstant5', 'Max', 'Break1', 'Break2', 'Break3']} />}
                        {active === 'Weeding' && <Configure type='Weeding' columns={['Crop','AndelHalmav', 'Fosfor', 'Kalium','BortPRate','BortKRate']} />}
                    </div>
                </div>
            </div >
        ) : null;
    }
}

export default AdminPage;